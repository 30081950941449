@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Play&display=swap');


body{
    font-family: 'Merriweather Sans', sans-serif;
    font-family: 'Play', sans-serif;
}








/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(53, 53, 53); 
  }

  .lgin{
    background: rgba(150, 150, 150, 0.15);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10px );
    -webkit-backdrop-filter: blur( 10px );
    border-radius: 6px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}